import React, { useEffect } from 'react';
import { Text, View } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

export default function App() {
  /// parse url parameters:
  let url = window.location.href;
  let app_id = url.split('app_id=')[1].split('&')[0];
  let campaign_name = url.split('campaign_name=')[1].split('&')[0];
  let source_id = url.split('source_id=')[1].split('&')[0];
  let campaign_id = url.split('campaign_id=')[1].split('&')[0];
  let click_id = url.split('click_id=')[1].split('&')[0];
  let country = url.split('country=')[1].split('&')[0];
  console.log("all parameters: "+app_id+" "+campaign_name+" "+source_id+" "+campaign_id+" "+click_id+" "+country)
  let link = `https://impression.appsflyer.com/${app_id}?c=${campaign_name}&pid=mgmedia_int&af_viewthrough_lookback=1d&af_siteid=${source_id}`
  useEffect(() => {
    fetch(link)
      .then(response => response.json())
      .then(data => {
        // now 'data' will contain the IP address of the client
        console.log(data);
        WebBrowser.openBrowserAsync('https://apps.apple.com/app/lea-therapy-chatbot/id1631059076');
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Redirecting...</Text>
    </View>
  );
}
